// PolaroidPile.js

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import API from '../lib/API';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: visible; /* Make overflow visible */
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Polaroid = styled(motion.div)`
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border: 3px solid white;
  border-radius: 3px;
  background: '#fff';
  padding-bottom: 0px; 
  margin: 6px 2px 2px;
`;

const SquarePolaroid = styled(Polaroid)`
  
`;

const getPolaroidDimensions = (orientation, multiplier = 0.2) => {
  switch (orientation) {
    case 'landscape':
      return { width: 250 * multiplier, height: 200 * multiplier };
    case 'portrait':
      return { width: 200 * multiplier, height: 250 * multiplier };
    case 'square':
      return { width: 175 * multiplier, height: 175 * multiplier }; // Update dimensions for square images
    default:
      return { width: 200 * multiplier, height: 250 * multiplier };
  }
};

const PolaroidList = ({ images, maxAngle = 10 }) => {
  const [loaded, setLoaded] = useState(false);
  
  return (
    <Container>
      {images.map((img, index) => {
        const { url, orientation } = img;
        const { width: imgWidth, height: imgHeight } = getPolaroidDimensions(orientation, 1);
        const PolaroidComponent = orientation === 'square' ? SquarePolaroid : Polaroid;

        return (
          <PolaroidComponent
            key={index}
            custom={index}
            style={{ transform: `rotate(${Math.random() * maxAngle - maxAngle / 2}deg` }}
          >
            <img style={{ height: '70px', marginTop: '-10px' }} src={API.getImageUrl(url, 100)} />
          </PolaroidComponent>
        );
      })}
    </Container>
  );
};

export default PolaroidList;
