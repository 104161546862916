const API_URL = process.env.REACT_APP_API_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default {
    getImageUrl: function (url, width, rotation) {
        return `${SERVER_URL}/api/image?url=${encodeURIComponent(url)}&width=${width}&rotation=${rotation}`;
    },
    getPrintingServices: async function () {
        const res = await fetch(`${API_URL}/api/printing-services/list`);
        return await res.json();
    },
    getOtp: async function () {
        const options = {
            method: 'POST'
        };
        const res = await fetch(`${API_URL}/api/payment/get-otp`, options);
        return (await res.json()).id;
    },
    deleteImage: async function (id) {
        return await fetch(`${API_URL}/api/images/delete`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id }) });
    },
    updateAllImageSizes: async function(session, size) {
        return await fetch(`${API_URL}/api/images/update-sizes`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ jobId: session.activeJob.id, size }) });
    },
    updateImage: async function(image) {
        return await fetch(`${API_URL}/api/images/update`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ image }) });
    },
    uploadFiles: async function (session, files) {
        const formData = new FormData();
        formData.append('userId', session.user.id);
        for (let i = 0; i < files.length; i++) {
            formData.append('images', files[i]);
        }
        return await fetch(`${SERVER_URL}/api/upload`, {
            method: 'POST',
            body: formData,
        });
    },
    placeOrder: async function (session) {
        const printjobResponse = await fetch(`${API_URL}/api/jobs/execute`, { method: 'POST', body: JSON.stringify({ jobId: session.activeJob.id }), headers: { 'Content-Type': 'application/json' } });
        return await printjobResponse.json();
    },
    getJobStatus: async function (jobId) {
        const response = await fetch(`${API_URL}/api/jobs/get-progress?jobId=${jobId}`);
        return await response.json();
    },
    getImagesForJob: async function (jobId) {
        const res = await fetch(`${API_URL}/api/images/get-by-job?jobId=${jobId}`);
        return await res.json();
    },
    setPrintingService: async function (session, printingService) {
        const res = await fetch(`${API_URL}/api/jobs/set-printing-service`, { method: 'PUT', body: JSON.stringify({ jobId: session.activeJob.id, printingService }), headers: { 'Content-Type': 'application/json' } });
        return await res.json();
    }
};