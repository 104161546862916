import React, { useState, useEffect, useRef } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { useSession } from '../SessionContext';
import Header from './Header.tsx';
import Footer from './Footer.tsx';
import Layout from './Layout.tsx';
import { Card, IconButton, AspectRatio, CardContent, Stack, Select, Option, Grid, Container, CircularProgress, Table } from '@mui/joy';
import { BookmarkAdd, DeleteOutline, Print, FileUpload } from '@mui/icons-material';
import moment from 'moment';
import OrientedImage from './OrientedImage';
import { OpenCvProvider } from 'opencv-react';

import bootsLogo from '../images/boots-logo.svg';
import snapfishLogo from '../images/snapfish-logo.png';
import { TableHead, TableRow } from '@mui/material';
import PolaroidPile from './PolaroidPile.jsx';
import PolaroidList from './PolaroidList.jsx';

import API from '../lib/API.js';

const OrderHistory = () => {
    const { session, logout, refreshSession } = useSession();
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        async function loadImages() {
            const loadedJobs = [];
            for (let job of session.previousJobs) {
                const images = await API.getImagesForJob(job.id);
                job.photos = images;
                loadedJobs.push(job);
            }
            setJobs(loadedJobs);
            hideLoading();
        }

        loadImages();
    }, [session]);

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }

    return (
        <CssVarsProvider>
            <Layout.Header>
                <Header />
            </Layout.Header>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, p: 3, mb: 13 }}>
                    {session.previousJobs.length === 0 && (
                        <>
                            <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                My Order History
                            </Typography>
                            <Typography level="p" component="p" sx={{ mb: 2 }}>
                                You have no previous orders
                            </Typography>
                        </>
                    )}
                    {session.previousJobs.length > 0 && (
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                            <Grid item>
                                <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                    My Order History
                                </Typography>
                                <Typography level="p" component="p" sx={{ mb: 2 }}>
                                    Your previous orders are shown below. 
                                </Typography>
                            </Grid>
                            <Grid item display="flex" flexDirection="row" alignItems="flex-end">
                                {loading && (
                                    <CircularProgress size='md' />
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {jobs.length > 0 && (
                        <>
                            <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th width="20%">Date</th>
                                            <th width="10%">Status</th>
                                            <th width="10%">Printer</th>
                                            <th width="60%">Photos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobs.map(job => (
                                            <tr>
                                                <td>
                                                    {moment(new Date(job.createdAt)).format('dddd, DD MMMM YYYY')}
                                                </td>
                                                <td>
                                                    {job.status}
                                                </td>
                                                <td>
                                                    PRINTER
                                                </td>
                                                <td align="left">
                                                    <PolaroidList images={job.photos.slice(0, 12)} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Stack>
                        </>
                    )}
                </Box>
            </Box>
        </CssVarsProvider>
    );
};

export default OrderHistory;
